<template>
	<b-container class="pb-3 page">
		<div v-show="loading" style="position: absolute; top: 50%; left: 50%">
			<b-spinner></b-spinner>
		</div>
		<b-row>
			<b-col>
				<h2 class="d-flex justify-content-center mt-5">정산하기</h2>
			</b-col>
		</b-row>
		<div class="d-flex mt-4">
			<div class="d-flex">
				<span class="mx-1" style="writing-mode: tb-rl">파티장</span>
				<span style="writing-mode: tb-rl">파티원</span>
			</div>
			<span class="w-50"
				><b-icon icon="person-fill" class="m-3 h5"></b-icon
			></span>
			<span class="m-2 w-100 text-center pt-1">식비</span>
			<span class="m-2 w-100"
				><b-form-input
					v-model="delivery_sum"
					@input="handle_sum()"
					type="number"
					placeholder="배달비 or n빵"
					style="text-align: right"
					:disabled="!sumInputUsable"
				></b-form-input
			></span>
		</div>
		<div v-for="(user, index) in userList" :key="index" class="d-flex">
			<b-form-checkbox
				@change="onChiefCheck(user.id)"
				v-model="user.is_chief"
				class="m-2"
				:disabled="!user.chiefBtnUsable"
			></b-form-checkbox>
			<b-form-checkbox
				@change="onInCheck()"
				v-model="user.is_member"
				class="m-2"
				:disabled="!user.memberBtnUsable"
			></b-form-checkbox>
			<span class="m-2 align-middle w-50">{{ user.name }}</span>
			<b-form-input
				@input="costChange()"
				v-model="user.cost"
				min="0"
				placeholder="0"
				type="number"
				class="m-2"
				style="text-align: right"
				:disabled="!user.costInputUsable"
			></b-form-input>
			<b-form-input
				v-model="user.delivery_cost"
				placeholder="0"
				type="number"
				class="m-2"
				style="text-align: right"
				disabled
			></b-form-input>
		</div>
		<div id="radio" class="d-flex m-2 justify-content-between">
			<b-form-group style="w-100">
				<b-form-radio-group
					v-model="reportType"
					@change="handleType"
					:options="typeOptions"
					class="d-flex justify-content-between w-100"
				></b-form-radio-group>
			</b-form-group>
			<b-form-input
				:disabled="!typeInputUsable"
				v-model="typeDetail"
			></b-form-input>
		</div>
		<div class="mt-4">
			<label for="formFile" class="form-label">영수증</label>
			<b-form-group class="mb-2">
				<b-form-radio-group
					@change="receiptChange"
					v-model="receiptValue"
					:options="receiptOptions"
					class="d-flex"
				></b-form-radio-group>
			</b-form-group>
			<input
				v-if="receiptValue"
				@change="onFile"
				ref="receipt"
				class="form-control"
				type="file"
				id="formFile"
				accept=".jpg,.jpeg,.gif,.png,.pdf"
			/>
			<b-form-input
				v-else
				placeholder="사유를 작성하세요."
				v-model="noReceiptReason"
			></b-form-input>
		</div>
		<div class="mt-4">
			<label for="textarea-small">메모</label>
			<b-form-textarea
				id="textarea-small"
				size="sm"
				placeholder="메모를 입력하세요"
				v-model="memo"
				class="mt-2"
			></b-form-textarea>
		</div>
		<div class="d-flex justify-content-center mt-4">
			<b-button v-if="isCreate" @click="reset()">초기화</b-button>
			<b-button v-else @click="resetInit()">초기화</b-button>
			<b-button @click="submitReport" style="margin-left: 0.5rem"
				><span v-if="isCreate">저장</span><span v-else>수정</span></b-button
			>
			<b-button
				@click="$router.push({ name: 'reports' })"
				variant="outline-secondary"
				style="margin-left: 0.5rem"
				>취소</b-button
			>
		</div>
	</b-container>
</template>
<script>
import ApiService from "../common/api.service"
import { consoleLog, errorLog, modalErr } from "../common/utils"
import { cloneDeep } from "lodash/fp"

export default {
	props: ["id", "data"],
	created() {
		consoleLog("report form page start")
		this.getUserList()
	},
	data() {
		return {
			loading: false,
			isCreate: true,
			delivery_sum: null,
			sumInputUsable: false,
			reportType: "점심",
			typeInputUsable: false,
			typeDetail: "",
			receiptValue: true,
			receiptOptions: [
				{ text: "있음", value: true },
				{ text: "없음", value: false },
			],
			noReceiptReason: "",
			memo: "",
			typeOptions: [
				{ text: "점심", value: "점심" },
				{ text: "저녁", value: "저녁" },
				{ text: "기타", value: "기타" },
			],
			userList: [],
			initUserList: [],
			imageFile: "",
		}
	},
	methods: {
		// 페이지 create 시 user 목록 가져옴
		async getUserList() {
			await ApiService.get("users")
				.then(({ data }) => {
					consoleLog("user list response : ", data)
					this.userList = data.data.map((user) => ({
						...user,
						detail_id: null,
						cost: 0,
						delivery_cost: 0,
						is_chief: false,
						is_member: false,
						chiefBtnUsable: true,
						memberBtnUsable: false,
						costInputUsable: false,
					}))
					// 생성이 아닌 수정인 경우 props 데이터로 변경
					if (this.data) {
						consoleLog("this data : ", this.data)
						this.isCreate = false
						this.memo = this.data.memo
						this.type = this.data.type
						// this.imageFile = this.data.receipt
						if (this.data.receipt) {
							const dataTransfer = new DataTransfer()
							const file = new File(["content"], this.data.receipt_name)
							dataTransfer.items.add(file)
							this.$refs.receipt.files = dataTransfer.files
							this.receiptValue = true
						} else {
							this.receiptValue = false
						}
						this.imageFile = this.data.receipt ? this.data.receipt : ""
						this.noReceiptReason = this.data.no_receipt
						if (this.data.type == "기타") {
							this.typeInputUsable = true
							this.reportType = this.data.type
							this.typeDetail = this.data.type_detail
						} else {
							this.reportType = this.data.type
						}
						const chief = this.data.detail.find((user) => user.is_chief == true)
						this.userList = this.userList.map((user) => ({
							...user,
							is_chief: user.id == chief.user_id ? true : false,
							chiefBtnUsable: user.id == chief.user_id ? true : false,
						}))
						this.sumInputUsable = true
						this.delivery_sum = this.data.delivery_sum
						const members = this.data.detail.filter(
							(user) => user.is_member == true
						)
						let list = [...this.userList]
						for (const member of members) {
							list.map((user) => {
								if (user.id == member.user_id) {
									user.detail_id = member.id
									user.is_member = true
									user.memberBtnUsable = true
									user.costInputUsable = true
									user.cost = parseInt(member.cost.replace(/,/g, ""))
									user.delivery_cost = parseInt(
										member.delivery_cost.replace(/,/g, "")
									)
								} else if (user.id == chief.user_id) {
									user.detail_id = chief.id
									user.cost = parseInt(chief.cost.replace(/,/g, ""))
									user.delivery_cost = parseInt(
										chief.delivery_cost.replace(/,/g, "")
									)
								} else {
									user.memberBtnUsable = true
								}
							})
						}
						this.userList = list
						this.initUserList = cloneDeep(list)
					}
					consoleLog("after data success", this.userList)
				})
				.catch((response) => {
					errorLog("error", response)
				})
		},
		// 저장 or 수정 버튼 click (report create)
		async submitReport() {
			const validate = this.validate(this.userList)
			if (validate) {
				this.loading = true
				const params = this.apiFormat()
				await ApiService.post("reports", params)
					.then(({ data }) => {
						consoleLog("report response data : ", data)
						if (data.result_code == 5000) {
							this.loading = false
							this.showMsgBoxValidate("서버에서 오류가 발생했습니다.")
						} else {
							if (this.imageFile !== "") {
								this.fileUpload(data.id)
							} else {
								this.loading = false
								this.$bvModal.msgBoxOk("저장되었습니다.").then(() => {
									this.$router.push({ name: "reports" })
								})
							}
							consoleLog("create or update response success : ", data)
						}
					})
					.catch((response) => {
						errorLog(response)
						this.loading = false
						this.$bvModal
							.msgBoxOk("서버에서 오류가 발생하였습니다.")
							.then(() => {
								this.$router.push({ name: "reports" })
							})
					})
			}
		},
		// 초기화 버튼을 누르거나 파티장을 해제했을 때
		reset() {
			this.sumInputUsable = false
			this.delivery_sum = null
			this.userList.map(
				(user) => (
					(user.chiefBtnUsable = true),
					(user.is_member = false),
					(user.is_chief = false),
					(user.memberBtnUsable = false),
					(user.cost = 0),
					(user.delivery_cost = 0),
					(user.costInputUsable = false)
				)
			)
			this.reportType = "점심"
			this.typeInputUsable = false
			this.typeDetail = ""
			this.receiptValue = true
			this.noReceiptReason = ""
			this.memo = ""
			if (this.imageFile !== "") {
				this.$refs.receipt.value = ""
			}
			this.imageFile = ""
		},
		// 수정 페이지에서 초기화 버튼을 눌렀을 때 초기 데이터로 변환
		resetInit() {
			this.userList = cloneDeep(this.initUserList)
		},
		// 파티장 체크박스 핸들링
		onChiefCheck(userId) {
			// chief 가 check 되어 있으면 객체 반환 없으면 빈 배열 반환
			const checkedChief = this.userList.filter(
				(user) => user.is_chief === true
			)
			// chief 가 있으면 나머지 chief checkbox disabled, chief의 파티원 checkbox disabled
			if (checkedChief.length) {
				this.sumInputUsable = true
				this.userList.map((user) =>
					user.id === userId
						? ((user.chiefBtnUsable = true), (user.memberBtnUsable = false))
						: ((user.chiefBtnUsable = false), (user.memberBtnUsable = true))
				)
			} else {
				this.reset()
			}
		},
		// 파티원 체크박스 핸들링
		onInCheck() {
			// chief 가 check 되어 있으면 객체 반환 없으면 빈 배열 반환
			const checkedChief = this.userList.filter(
				(user) => user.is_chief === true
			)

			this.userList.map((user) => {
				user.is_member === true
					? (user.costInputUsable = true)
					: ((user.costInputUsable = false), (user.cost = null))
			})

			this.costChange()
			this.calDeliveryCost(this.delivery_sum, checkedChief[0])
		},
		// 식비 입력하거나 수정할 때마다 금액 변환
		costChange() {
			const inObj = this.userList.filter((user) => user.is_member === true)
			const result = inObj.reduce((acc, cur) => {
				console.log(cur, " : ", cur.cost)
				if (cur.cost === null || cur.cost === "" || cur.cost === "NaN") {
					cur.cost = 0
				}
				if (typeof cur.cost == "string") {
					cur.cost = parseInt(cur.cost)
				}
				return acc + cur.cost
			}, 0)
			let list = [...this.userList]
			list.map((user) => {
				if (user.is_chief === true) {
					user.cost = result * -1
				}
			})
			this.userList = list
		},
		// 배달비 or n빵 금액 입력시 파티원들에게 나누어 계산
		calDeliveryCost(val, chiefObj) {
			// member 객체 리스트 가져오기
			const inObj = this.userList.filter((user) => user.is_member === true)
			this.userList.map((user) =>
				user.is_member === false ? (user.delivery_cost = 0) : null
			)
			const nVal = val / (inObj.length + 1)
			// 엔빵 금액이 정수와 소수인 경우로 나누어 결과값 계산 (소수의 경우 백원 단위로 올림)
			this.userList.map((user) => {
				const val = Number.isInteger(nVal) ? nVal : Math.ceil(nVal)
				inObj.map((obj) => (obj === user ? (user.delivery_cost = val) : null))
				user === chiefObj
					? (user.delivery_cost = val * inObj.length * -1)
					: null
			})
		},
		// 배달비 합계를 계산
		handle_sum() {
			const chiefObj = this.userList.filter((user) => {
				return user.is_chief === true
			})[0]
			this.calDeliveryCost(this.delivery_sum, chiefObj)
		},
		// type checkbox 에서 기타 선택할 경우 input disabled or enabled
		handleType() {
			this.typeInputUsable = this.reportType === "기타" ? true : false
		},
		// 저장 눌렀을 때 유효성 검사하는 함수
		validate(userList) {
			const checkedChief = userList.filter((user) => {
				return user.is_chief === true
			})
			if (checkedChief.length == 0) {
				this.showMsgBoxValidate("파티장을 선택하세요.")
				return false
			}
			const checkedMember = userList.filter((user) => {
				return user.is_member === true
			})
			if (checkedMember.length == 0) {
				this.showMsgBoxValidate("파티원을 선택하세요.")
				return false
			}
			const cost = userList.filter((user) => {
				if (user.is_member === true)
					return (
						(user.cost != 0 && user.cost !== null) ||
						(user.delivery_cost != 0 && user.delivery_cost !== null)
					)
			})
			if (cost.length == 0 || cost.length !== checkedMember.length) {
				this.showMsgBoxValidate("식비나 배달비를 입력하세요.")
				return false
			}
			if (
				this.reportType == "기타" &&
				(this.typeDetail == null || this.typeDetail == "")
			) {
				this.showMsgBoxValidate("기타를 입력하세요.")
				return false
			}
			if (
				this.receiptValue == true &&
				(this.imageFile == "" || this.imageFile == null)
			) {
				this.showMsgBoxValidate("파일을 첨부하세요.")
				return false
			} else if (
				this.receiptValue == false &&
				(this.noReceiptReason == "" || this.noReceiptReason == null)
			) {
				this.showMsgBoxValidate("사유를 입력하세요.")
				return false
			}

			return true
		},
		// 생성, 수정 데이터 포맷이 같아서 함수로 따로 만들어둠
		apiFormat() {
			const detail = this.userList.map((user) => {
				return {
					user_id: user.id,
					user_name: user.name,
					cost: user.cost !== null ? user.cost : 0,
					delivery_cost: user.delivery_cost !== null ? user.delivery_cost : 0,
					is_chief: user.is_chief,
					is_member: user.is_member,
				}
			})
			const params = {
				type: this.reportType,
				type_detail: this.typeDetail,
				memo: this.memo,
				delivery_sum:
					this.delivery_sum == null ? 0 : parseInt(this.delivery_sum),
				no_receipt: this.noReceiptReason,
				detail: detail,
				pre_report_id: this.id ? this.id : null,
			}
			return params
		},
		// 영수증 radio button 이벤트 핸들러
		receiptChange() {
			if (this.receiptValue == false) {
				this.imageFile = ""
			} else if (this.receiptValue == true) {
				this.noReceiptReason = ""
			}
		},
		// 파일 첨부시 반응하는 이벤트 핸들러
		onFile() {
			let files = this.$refs.receipt.files
			if (files.length) {
				this.imageFile = files[0]
			} else {
				consoleLog("no files")
			}
		},
		// 파일 저장
		async fileUpload(dataId) {
			consoleLog("file upload start")
			const header = { header: { "Context-Type": "multipart/form-data" } }
			let isChange = true
			if (
				this.data &&
				this.data.receipt_name == this.$refs.receipt.files[0].name
			) {
				isChange = false
			}
			const formData = new FormData()
			formData.append("receipt", this.imageFile)
			formData.append("id", dataId)
			formData.append("is_change", isChange)
			await ApiService.post("file-upload", formData, header)
				.then(({ data }) => {
					// status 만 받아도 됨 추후에 수정해야 함
					consoleLog("receipt file response data : ", data)
					this.loading = false
					if (data.result_code == 0) {
						consoleLog("file upload success data : ", data)
						this.$bvModal.msgBoxOk("저장되었습니다.").then(() => {
							this.$router.push({ name: "reports" })
						})
					} else {
						this.$bvModal.msgBoxOk("파일 업로드에 실패했습니다.").then(() => {
							this.imageFile = ""
							this.receiptValue = false
							this.noReceiptReason = "파일 업로드 실패"
							this.submitReport()
						})
					}
				})
				.catch((response) => {
					this.loading = false
					errorLog(response, "reports")
				})
		},
		showMsgBoxValidate(msg) {
			this.$bvModal
				.msgBoxOk(msg)
				.then((value) => {
					this.boxOne = value
				})
				.catch((err) => {
					modalErr(err)
				})
		},
	},
}
</script>
<style>
#radio > .form-group {
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.custom-radio {
	margin-right: 0.5rem;
	/* display: flex; */
	align-items: center;
}
.custom-radio > .custom-control-input {
	margin-right: 0.3rem;
}
#radio > .form-group > div {
	width: 100%;
}
</style>
